
import MainPage from './src/components/MainPage';

import { api_login, api_logout } from './src/utils/api/backendLink.js';

import { useAuthenticated } from './src/contexts/useAuthenticated.js';
// import { useAuthenticated } from './src/contexts/UserContext.jsx';

// import { useState, useEffect } from 'react';
import LoginModal from './src/components/LoginModal'; // Crear el componente LoginModal
import { saveTokenAndUser, clearTokenAndUser, getToken, getUser } from './src/utils/AuthService';

const App = () => {
  //const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());  
  
  const [isAuthenticated, setIsAuthenticated] = useAuthenticated(!!getToken());

  // const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLogin = async (user, password) => {
    // Llamada a la API para autenticarse
    try {
      const response = await api_login(user, password);
      if (response.success) {
        saveTokenAndUser(response.token, { name: response.name, email: user });
        setIsAuthenticated(true);
        // setShowLoginModal(false);
      } else {
        // Mostrar ventana emergente para reintentar
        alert('Invalid email or password. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleLogout = async () => {
    clearTokenAndUser();
    setIsAuthenticated(false);
    // setShowLoginModal(true);
    try {
      const response = await api_logout();
      console.log(`Logout: ${response.data}`); 
      // if (response.success) {
      //   console.log('Logout succesfully'); 
      // } else {
      //   console.error('Error in Logout');         
      // }
    } catch (error) {
      console.error('Error during login:', error);
    }

  };

  // useEffect(() => {    
  //   // Verificar la validez del token al cargar la aplicación
  //   const checkTokenValidity = async () => {
  //     if (!getToken()) setIsAuthenticated(false);
  //   };

  //   checkTokenValidity();
  // }, []);

  return (
    <div>
      {/* Contenido de la aplicación */}
      {isAuthenticated ? (
        <div>
          <h3>Bienvenido, {getUser().name}!</h3>
          <button className="close_button" onClick={handleLogout}>Cerrar Sesión</button>
          <br></br>
          <MainPage />
          {/* Resto de tu contenido después de iniciar sesión */}
        </div>
      ) :
        <div>
          {<LoginModal onLogin={handleLogin} />}
        </div>
      }
    </div>
  );
};

export default App;

