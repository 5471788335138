import axios from "axios";

import { getToken } from '../AuthService';
import { getBackendURL, getApiToken } from '../OtherServices';

//Login using user/password
const api_login = async (user, password) => axios(
  {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${getBackendURL()}/auth/login`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      "email": user,
      "password": password
    }
  }
)
  .then((response) => {    
    return response.data
  })
  .catch((error) => {    
    alert(error)
    return error
  });

  //Login using user/password
const api_logout = async () => axios(
  {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${getBackendURL()}/auth/signout`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
)
  .then((response) => {    
    return response.data
  })
  .catch((error) => {    
    alert(error)
    return error
  });

//List the server files
const api_listFiles = async () => axios(
  {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${getBackendURL()}/files/list`,
    headers: { 
    'Authorization': `Bearer ${getToken()}`,
    'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then((response) => {    
    return response.data
  })
  .catch((error) => {        
    throw(error.response)    
  });
  
  const api_downloadFile = async (file) => axios(
    {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${getBackendURL()}/files/download/${file}`,
      headers: { 
      'Authorization': `Bearer ${getToken()}`,
      'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',       
    })
    .then((response) => {    
      return response.data
    })      
    .catch((error) => {                
      //Status: 901 (means Token invalido)
      throw(error.response)      
    });

const api_downloadFileS3 = async (file) => axios(
  {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${getBackendURL()}/files/download/${file}`,
    headers: { 
    'Authorization': `Bearer ${getToken()}`,
    'Content-Type': 'application/x-www-form-urlencoded'
    },
  })
  .then((response) => {    
    return response.data
  })      
  .catch((error) => {                
    //Status: 901 (means Token invalido)
    throw(error.response)      
  });
    
const api_getByEmail = async (email) => axios(
  {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${getBackendURL()}/user/email/${email}`,
    headers: { 
    'Authorization': `Bearer ${getApiToken()}`,
    'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then((response) => {    
    return response.data
  })
  .catch((error) => {        
    throw(error.response)    
  });
  

export { api_login, api_logout, api_listFiles, api_downloadFile, api_getByEmail, api_downloadFileS3 };