import './MainPage.css';

import { useState, useEffect } from 'react';
import FileList from './FileList';
import FileViewer from './FileViewer';

import {api_listFiles} from '../utils/api/backendLink.js';

import { handleTokenExpiration } from '../utils/AuthService';

import { useAuthenticated } from '../contexts/useAuthenticated.js';

import {saveModeDownloadFiles} from '../utils/OtherServices';

const MainPage = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [isAuthenticated, setIsAuthenticated] = useAuthenticated();

  useEffect(() => {
    
    // Call to API to get the file list
    const fetchData = async () => {
      try {
        const response = await api_listFiles();
        saveModeDownloadFiles(response.mode);
        setFiles(response.files);

      } catch (error) {
        if (error?.data?.message === 'Token expired') {
          console.error('Token Expired');
          alert('Expired Session. Enter credentials again');
          handleTokenExpiration();
          setIsAuthenticated(false);
        } else console.log("Error in api_listFiles():", error)
      }       
    }
    fetchData();
  }, [setIsAuthenticated]);

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  return (
    <div className="main-container">
      <FileList files={files} onFileClick={handleFileClick} />
      <FileViewer file={selectedFile} />
    </div>
  );
};

export default MainPage;