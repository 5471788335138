/* eslint-disable react/prop-types */

import './MainPage.css';

const FileList = ({ files, onFileClick }) => {

  return (
    <div className="file-list">
      <h1>Media files</h1>
      <div>
        {files.map((file, index) => (
          <div key={index} className="file-item" onClick={() => onFileClick(file.name)}>
            <img src={file.thumbnail} alt={file.name} />
            <h3>{file.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};


export default FileList;
