const MODE_DOWNLOAD_FILES = 'mode_download_files';

// const userAgent = navigator.userAgent;
const screenWidth = window.screen.width;
const screenHeight = window.screen.height;

const deviceInfo = {
  platform: navigator.platform,
  userAgent: navigator.userAgent,
  appName: navigator.appName,
  appVersion: navigator.appVersion,
  screenWidth: screenWidth,
  screenHeight: screenHeight
};


// console.log("User Agent:", userAgent);
// console.log("Resolución de Pantalla:", screenWidth, "x", screenHeight);
// console.log("Información del Dispositivo:", deviceInfo);


export const getLocalInfo = () => {
  return deviceInfo;
}

export const saveModeDownloadFiles = (mode) => {
  localStorage.setItem(MODE_DOWNLOAD_FILES, mode);  
};

export const getBackendURL = () => import.meta.env.VITE_REACT_APP_BACKEND_URL;
export const getApiToken = () => import.meta.env.VITE_REACT_APP_API_TOKEN;
export const getModeDownloadFiles = () => localStorage.getItem(MODE_DOWNLOAD_FILES);