/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { handleTokenExpiration } from '../utils/AuthService';
import { api_downloadFile, api_downloadFileS3 } from '../utils/api/backendLink.js';
import ReactPlayer from 'react-player';
import { useAuthenticated } from '../contexts/useAuthenticated.js';
import spinnerImage from '../assets/caracol.gif';

import { getModeDownloadFiles } from '../utils/OtherServices';

const imageExtensions = ['.jpg', '.jpeg', '.bmp', '.gif'];

import './MainPage.css';

const FileViewer = ({ file }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isAuthenticated, setIsAuthenticated] = useAuthenticated();

  useEffect(() => {
    setIsLoading(false); 
  }, []);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        //for server local files:
        let response = undefined;
        let fileUrl = undefined;

        if (getModeDownloadFiles() === 'local') {
          response = await api_downloadFile(file);
          fileUrl = URL.createObjectURL(await response);
        } else if (getModeDownloadFiles() === 's3') {
          //for AWS S3 files
          response = await api_downloadFileS3(file);
          fileUrl = response.url
        } 

        setFileUrl(fileUrl);
      } catch (error) {
        if (error.status === 901) {
          console.error('Token Expired');
          alert('Expired Session. Enter credentials again');
          handleTokenExpiration();
          setIsAuthenticated(false);
        } else console.log('Error in api_downloadFileS3():', error);
      } finally {
        setIsLoading(false); // Indicar que la carga ha finalizado
      }
    };

    if (file) {
      setIsLoading(true); // Indicar que la carga ha comenzado
      fetchFile();
    }
  }, [file, setIsAuthenticated]);

  return (
    <div className="file-viewer">      
      {isLoading &&       
        <img className="spinner" src={spinnerImage} alt="Spinner" />
      }
      {file && !isLoading && (
        <div className="child-div">
          {imageExtensions.some((ext) => file.endsWith(ext)) ? (
            <img className="file-play" src={fileUrl} alt={file} />
          ) : file.endsWith('.mp4') ? (
            fileUrl && (
              <ReactPlayer
                url={fileUrl}
                width="100%"
                height="100vh"
                controls
                playing
                style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '80vh' }}
              />
            )
          ) : file.endsWith('.pdf') ? (
            fileUrl && (
              <iframe
                src={fileUrl}
                title="PDF Viewer"
                width="100%"
                height="100%"
                allowFullScreen
              />
            )
          ) : (
            <p>Can not show the file</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FileViewer;
